@import "_slick";
@import "_slick-theme";

body{
	font-family: $ff_ja;
	font-weight: 500;
	
	@if $type == sp{
		font-family: sans-serif;
	}
}


.eng{
	font-family: $ff_eng;
}

.sten{
	font-family: $ff_sten;
	font-weight: 400 !important;
}



/* ----------------scroll view ------------------- */

.content{
	transform: translateX(-80px);
	opacity: 0;
	transition: all 1s;

	&.view{
		transform: translateX(0);
		opacity: 1;
	}
	
	@if $type == sp{
		transform: translateY(80px);
		opacity: 0;
		transition: all 1s;

		&.view{
			transform: translateY(0);
			opacity: 1;
		}
	}
}

/* ---------------- header ----------------------- */

header{
	min-width: 100%;
	height: 160px;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 999;
	padding: 64px 80px 0;
	transition: all .3s;
//	overflow: hidden;

	&.scroll{
	//	height: 100px;
		height: 0;
		top: 45px;
		padding: 15px 80px 0;

		#backG{
			bottom: 0;
		}

		#siteName{
			width: 220px;
			height: 70px;
			background-size: 220px 70px;

			img{
				width: 220px;
				height: 70px;
				opacity: 0;
			}
		}

		#menuBtn{

			.txt{
				color: #888788;
			}
		}
	}

	&.isMenuOpen{
		height: 1px;
		padding: 0px 80px 0;
		top: 0;

		#siteName{
			width: 302px;
			height: 96px;
			background-size: 302px 96px;
			margin-top: 222px;

			img{
				width: 302px;
				height: 96px;
				opacity: 1;
			}
		}

		#menuBtn{
			margin-top: 222px;
			.txt{
				color: #fff;
			}
		}
	}

	#siteName{
		width: 302px;
		height: 96px;
		transition: all .3s;
		background-image: url(../images/common/logo2.png);
		background-size: 302px 96px;
		background-repeat: no-repeat;
		position: relative;

		img{
			width: 302px;
			height: 96px;
			transition: all .3s;
		}

		.memberTag{
			width: 111px;
			height: 25px;
			position: absolute;
			bottom: 21px;
			right: -120px;

			img{
				width: 100%;
				height: auto;
			}
		}
	}

	#menuBtn{
		width: 87px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		height: 22px;

		#icon{
			width: 20px;
			height: 21px;
			position: relative;

			span{
				display: block;
				width: 20px;
				height: 3px;
				background-color: $green;
				position: absolute;
				transition: all .4s;

				&:nth-of-type(1){
					top: 0;
					left: 0;
				}

				&:nth-of-type(2){
					top: 9px;
					left: 0;
					width: 14px;
				}

				&:nth-of-type(3){
					top: 18px;
					left: 0;
				}
			}
		}

		.txt{
			font-size: 22px;
			line-height: 1;
			color: #fff;
		}

		&:hover{
			#icon{
				span:nth-of-type(2){
					width: 20px;
				}
			}
		}

		&.active{
			#icon{
				span{
					&:nth-of-type(1){
						top: 11px;
						left: 0;
						transform: rotate(315deg);
						background-color: #fff;
					}

					&:nth-of-type(2){
						opacity: 0;
					}

					&:nth-of-type(3){
						top: 11px;
						left: 0;
						transform: rotate(-315deg);
						background-color: #fff;
					}
				}
			}
		}
	}
	
	@if $type == tb or $type ==sp{
		height: 100px;
		padding: 30px 5% 0;
		transition: all .3s;
		overflow: hidden;

		&.scroll{
			height: 90px;
			padding: 10px 5% 0;

			#siteName{
				width: 220px;
				height: 70px;
				background-size: 220px 70px;

				img{
					width: 220px;
					height: 70px;
					opacity: 0;
				}
			}

			#menuBtn{
				width: 20px;
				transition: all .3s;

				.txt{
					width: 0;
					overflow: hidden;
					color: transparent;
				}
			}
		}

		&.isMenuOpen{
			height: 100px;
			padding: 30px 5% 0;

			#siteName{
				width: 220px;
				height: 70px;
				background-size: 220px 70px;

				img{
					width: 100%;
					height: auto;
					opacity: 1;
				}
			}

			#menuBtn{
				width: auto;
				
				.txt{
					width: auto;
					color: #fff;
					overflow: visible;
				}
			}
		}

		#siteName{
			width: 220px;
			height: 70px;
			transition: all .3s;
			background-image: url(../images/common/logo2.png);
			background-size: 220px 70px;
			background-repeat: no-repeat;
			position: relative;

			img{
				width: 100%;
				height: auto;
				transition: all .3s;
			}
			
			.memberTag{
				width: 60px;
				height: 14px;
				position: absolute;
				bottom: 10px;
				right: -70px;
				
				img{
					width: 100%;
					height: auto;
				}
			}
		}

		#menuBtn{
			#icon{
				span{
					&:nth-of-type(1){
					}
					&:nth-of-type(2){
					}
					&:nth-of-type(3){
					}
				}
			}
			.txt{
			}

			&:hover{
				#icon{
					span:nth-of-type(2){
					}
				}
			}

			&.active{
				#icon{
					span{
						&:nth-of-type(1){
						}

						&:nth-of-type(2){
						}

						&:nth-of-type(3){
						}
					}
				}
			}
		}
	}
	
	@if $type == sp{
		height: 70px;
		padding: 10px 5% 0;
		transition: all .3s;
		overflow: hidden;

		&.scroll{
			height: 70px;
			padding: 10px 5% 0;

			#siteName{
				width: 158px;
				height: 50px;
				background-size: 158px 50px;

				img{
					width: 158px;
					height: 50px;
					opacity: 0;
				}
			}

			#menuBtn{
				width: 20px;
				transition: all .3s;

				.txt{
					width: 0;
					overflow: hidden;
					color: transparent;
				}
			}
		}

		&.isMenuOpen{
			height: 70px;
			padding: 10px 5% 0;

			#siteName{
				width: 158px;
				height: 50px;
				background-size: 158px 50px;

				img{
					width: 100%;
					height: auto;
					opacity: 1;
				}
			}

			#menuBtn{
				width: auto;

				.txt{
					width: auto;
					color: #fff;
					overflow: visible;
				}
			}
		}

		#siteName{
			width: 158px;
			height: 50px;
			transition: all .3s;
			background-image: url(../images/common/logo2.png);
			background-size: 158px 50px;
			background-repeat: no-repeat;

			img{
				width: 100%;
				height: auto;
				transition: all .3s;
			}
		}

		#menuBtn{
			width: 20px;
			
			.txt{
				display: none;
			}
		}
	}
}


/* ------------------- menu --------------------- */

#menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	z-index: 998;
	transform: scale(0);
	opacity: 0;
	//transition: all .3s;
	background-image: url(../images/common/check-bg.png);

	&.active{
		transform: scale(.7);
		opacity: 1;
		animation: menuAnim .3s forwards;
	}

	@keyframes menuAnim{
		100%{transform: scale(1)}
	}

	#menuBody{
		width: 100%;
		height: 100%;
		background-color: $green;
		display: flex;
		justify-content: center;
		align-items: center;

		.menuList{
			ul{
				list-style: none;

				li{
					margin-bottom: .4em;

					a{
						text-decoration: none;
						color: #fff;
						line-height: 1;
						display: inline-block;
						position: relative;
						padding-bottom: .1em;

						&:after{
							content: "";
							display: block;
							width: 0;
							height: 3px;
							background-color: #fff;
							position: absolute;
							bottom: 0;
							left: 0;
							transition: all .4s;
						}

						&:hover{

							&:after{
								width: 100%;
							}
						}
					}
				}
			}
		}

		#mainMenu{
			ul{

				li{

					a{
						font-size: 4vw;
						font-weight: 700;
					}
				}
			}
		}

		#subMenu{
			margin-left: 100px;

			ul{
				width: 60px;

				li{
					width: 42px;
					margin-bottom: 25px;
					transition: all .3s;
					margin-left: 9px;
					
					&:hover{
						width: 60px;
						margin-left: 0;
					}

					a{
						display: block;
						width: 100%;
						text-decoration: none;
						
						&:after{
							display: none;
						}
						
						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
	


	@if $type == tb or $type ==sp{

		padding: 10px;

		#menuBody{

			.menuList{
				ul{

					li{
						margin-bottom: .4em;
					}
				}
			}

			#mainMenu{
				ul{

					li{

						a{
							font-size: 50px;
							font-weight: 700;
						}
					}
				}
			}

			#subMenu{
				margin-left: 100px;

				ul{

					li{

						a{
							font-size: 28px;
						}
					}
				}
			}
		}
	}
	
	
	@if $type ==sp{
		#menuBody{
			display: block;

			#mainMenu{
				ul{
					width: 90%;
					margin: 90px auto 0;
					
					li{
						a{
							font-size: 30px;
							font-weight: 700;
						}
					}
				}
			}

			#subMenu{
				width: 90%;
				margin: 30px auto 0;

				ul{
					display: flex;
					width: 100%;
					margin: 0 auto;

					li{
						margin: 0 15px 0 0;

						a{
							display: block;
							width: 40px;
							height: 40px;
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}
	}
}


/* ------------------------- footer ----------------------- */

footer{
	background-color: #050901;
	position: relative;
	color: #fff;
	
	margin-top: 100px;
	padding-top: 85px;

	#footerInner{
		max-width: 1280px;
		width: 90%;
		margin: 0 auto 0;

		#footerCatch{
			font-size: 42px;
			font-weight: 700;
			text-align: center;
			line-height: 1.3;
		}

		#kochira{
			font-size: 18px;
			line-height: 1;
			text-align: center;
			margin-top: 25px;
		}

		#footerEntry{
			margin-top: 40px;

			a{
				width: 485px;
				height: 70px;
				background-color: #fff;
				border: 2px solid $green;
				margin: 0 auto;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				color: #fff;
				transition: all .3s;
				position: relative;
				z-index: 1;
				font-weight: 700;
				transition: all .3s;
				box-shadow: 4px 6px 0 #b4e585;
				font-weight: 700;
				
				&:after{
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					background-color: $green;
					z-index: -1;
					transition: all .3s;
				}

				&:hover{
					color: $green;
					
					&:after{
						width: 0;
					}
				}
			}
		}

		#others{
			width: 100%;
			border-top: 1px solid rgba(#fff, .6);
			border-bottom: 1px solid rgba(#fff, .6);
			margin-top: 60px;
			padding: 30px 0;

			h2{
				font-size: 15px;
				line-height: 1;
				color: rgba(#fff, .6);
				text-align: center;
			}

			ul{
				list-style: none;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				li{
					margin: 0 20px;

					a{
						opacity: .6;
						transition: all .3s;
						max-width: 140px;
						display: block;
						text-align: center;
						
						svg{
							height: 80px;
							fill: #fff;
						}

						&:hover{
							opacity: 1;
						}
					}
					
					&:nth-of-type(3){
						height: 20px;
						
						a{
							
							svg{
								height: 20px;
							}
						}
					}
				}
			}
		}

		#footerCopy{
			margin-top: 35px;

			#footerMenu{
				list-style: none;
				text-align: center;

				li{
					display: inline-block;
					font-size: 12px;
					line-height: 1;

					a{
						color: #fff;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}

					&:not(:last-of-type){
						&:after{
							content: "/";
							display: inline-block;
							width: 20px;
							text-align: center;
							color: #fff;
						}
					}
				}
			}

			#ceo{
				text-align: center;
				margin-top: 30px;
				vertical-align: middle;
				padding-left: 105px;
				
				a{
					display: inline-block;
					border: 1px solid #fff;
					color: #fff;
					text-decoration: none;
					font-size: 12px;
					padding: 5px 10px;
					vertical-align: middle;
					transition: all .3s;
					margin-right: 10px;
					
					&:hover{
						background-color: $green;
					}
				}
				
				img{
					vertical-align: middle;
				}
			}

			.copy{
				text-align: center;
				line-height: 1;
				font-size: 10px;
				padding: 30px 0;
			}
		}
	}

	@if  $type == tb or $type == sp{
		margin-top: 80px;
		padding-top: 40px;
		#footerInner{

			#footerCatch{
				font-size: 30px;
			}

			#kochira{
				font-size: 16px;
				margin-top: 15px;
			}

			#footerEntry{
				margin-top: 20px;

				a{
					max-width: 485px;
					width: 80%;
					height: 50px;
					font-weight: 700;
				}
			}

			#others{
				margin-top: 30px;
				padding: 15px 0;

				h2{
					font-size: 15px;
				}

				ul{
					margin-top: 20px;

					li{
						margin: 0 10px;

						a{
							opacity: .6;
						}

						&:nth-of-type(3){
							height: 20px;

							a{

								svg{
									max-width: 100%;
								}
							}
						}
					}
				}
			}

			#footerCopy{
				margin-top: 20px;

				#footerMenu{

					li{

						a{
						}
					}
				}

				#ceo{
					margin-top: 20px;
				}

				.copy{
				}
			}
		}
	}

	@if $type == sp{
		#footerInner{

			#footerCatch{
				font-size: 25px;
			}

			#kochira{
				font-size: 16px;
				margin-top: 15px;
			}

			#footerEntry{
				margin-top: 20px;

				a{
					font-size: 14px;
				}
			}

			#others{

				h2{
				}

				li{
					max-width: 32%;

					a{
						display: block;
						width: 100%;
						height: auto;

						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}

			#footerCopy{

				#footerMenu{

				}

				#ceo{
				}

				.copy{
				}
			}
		}
	}
}

	

#pagetop{
	width: 28px;
	height: 15px;
	position: absolute;
	top: -50px;
	right: 0;
	left: 0;
	margin: 0 auto;
	cursor: pointer;

	&:hover{
		animation: pageTop 1s infinite;
	}

	@keyframes pageTop{
		30%{
			top: -40px;
		}
	}
	
}


#joinUs{
	position: fixed;
	
	@if $type == sp{
	}

	@if $type == pc{
		width: 167px;
		height: 167px;
		bottom: 50px;
		right: 50px;
		background-image: url(../images/common/joinus.png);
		opacity: 0;
		transform: scale(0);
		transition: all .2s;
		z-index: 9999;
		
		
		
		&.scroll{
			opacity: 1;
			transform: scale(1);
		}
		
		a{
			display: block;
			width: 100%;
			height: 100%;
			
			img{
				transform: scale(0);
				transition: all .2s ease-in;
			}
			
			&:hover{
				
				img{
					transform: scale(1);
				}
			}
		}
	}
}


.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}

