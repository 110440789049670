@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
@import url("https://fonts.googleapis.com/css?family=Oswald:400,700");
@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, footer, nav, section, figcaption, figure, main {
  display: block; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none;
  vertical-align: top; }

table {
  border-collapse: collapse; }

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/*日本語用（）*/
/*英語用*/
/* font */
@media screen and (max-width: 650px) {
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; }
  /*
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
*/
  /*
@function slick-image-url($url) {
	@if function-exists(image-url) {
			@return image-url($url);
	}
	@else {
			@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
			@return font-url($url);
	}
	@else {
			@return url($slick-font-path + $url);
	}
}
*/
  /* Slider

.slick-list {
	.slick-loading & {
			background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}
*/
  /* Icons 
@if $slick-font-family == "slick" {
	@font-face {
			font-family: "slick";
			src: slick-font-url("slick.eot");
			src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
			font-weight: normal;
			font-style: normal;
	}
}
*/
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    .slick-prev:hover, .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .slick-prev:hover:before, .slick-prev:focus:before,
      .slick-next:hover:before,
      .slick-next:focus:before {
        opacity: 1; }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0.25; }
    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .slick-prev {
    left: -25px; }
    [dir="rtl"] .slick-prev {
      left: auto;
      right: -25px; }
  .slick-next {
    right: -25px; }
    [dir="rtl"] .slick-next {
      left: -25px;
      right: auto; }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .slick-dots li button:hover, .slick-dots li button:focus {
          outline: none; }
          .slick-dots li button:hover:before, .slick-dots li button:focus:before {
            opacity: 1; }
        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          font-size: 6px;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
  body {
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
    font-weight: 500;
    font-family: sans-serif; }
  .eng {
    font-family: "Oswald", "sans-serif", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "meiryo"; }
  .sten {
    font-family: "Allerta Stencil", sans-serif;
    font-weight: 400 !important; }
  /* ----------------scroll view ------------------- */
  .content {
    transform: translateX(-80px);
    opacity: 0;
    transition: all 1s;
    transform: translateY(80px);
    opacity: 0;
    transition: all 1s; }
    .content.view {
      transform: translateX(0);
      opacity: 1; }
    .content.view {
      transform: translateY(0);
      opacity: 1; }
  /* ---------------- header ----------------------- */
  header {
    min-width: 100%;
    height: 160px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    padding: 64px 80px 0;
    transition: all .3s;
    height: 100px;
    padding: 30px 5% 0;
    transition: all .3s;
    overflow: hidden;
    height: 70px;
    padding: 10px 5% 0;
    transition: all .3s;
    overflow: hidden; }
    header.scroll {
      height: 0;
      top: 45px;
      padding: 15px 80px 0; }
      header.scroll #backG {
        bottom: 0; }
      header.scroll #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.scroll #siteName img {
          width: 220px;
          height: 70px;
          opacity: 0; }
      header.scroll #menuBtn .txt {
        color: #888788; }
    header.isMenuOpen {
      height: 1px;
      padding: 0px 80px 0;
      top: 0; }
      header.isMenuOpen #siteName {
        width: 302px;
        height: 96px;
        background-size: 302px 96px;
        margin-top: 222px; }
        header.isMenuOpen #siteName img {
          width: 302px;
          height: 96px;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        margin-top: 222px; }
        header.isMenuOpen #menuBtn .txt {
          color: #fff; }
    header #siteName {
      width: 302px;
      height: 96px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 302px 96px;
      background-repeat: no-repeat;
      position: relative; }
      header #siteName img {
        width: 302px;
        height: 96px;
        transition: all .3s; }
      header #siteName .memberTag {
        width: 111px;
        height: 25px;
        position: absolute;
        bottom: 21px;
        right: -120px; }
        header #siteName .memberTag img {
          width: 100%;
          height: auto; }
    header #menuBtn {
      width: 87px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 22px; }
      header #menuBtn #icon {
        width: 20px;
        height: 21px;
        position: relative; }
        header #menuBtn #icon span {
          display: block;
          width: 20px;
          height: 3px;
          background-color: #71bd29;
          position: absolute;
          transition: all .4s; }
          header #menuBtn #icon span:nth-of-type(1) {
            top: 0;
            left: 0; }
          header #menuBtn #icon span:nth-of-type(2) {
            top: 9px;
            left: 0;
            width: 14px; }
          header #menuBtn #icon span:nth-of-type(3) {
            top: 18px;
            left: 0; }
      header #menuBtn .txt {
        font-size: 22px;
        line-height: 1;
        color: #fff; }
      header #menuBtn:hover #icon span:nth-of-type(2) {
        width: 20px; }
      header #menuBtn.active #icon span:nth-of-type(1) {
        top: 11px;
        left: 0;
        transform: rotate(315deg);
        background-color: #fff; }
      header #menuBtn.active #icon span:nth-of-type(2) {
        opacity: 0; }
      header #menuBtn.active #icon span:nth-of-type(3) {
        top: 11px;
        left: 0;
        transform: rotate(-315deg);
        background-color: #fff; }
    header.scroll {
      height: 90px;
      padding: 10px 5% 0; }
      header.scroll #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.scroll #siteName img {
          width: 220px;
          height: 70px;
          opacity: 0; }
      header.scroll #menuBtn {
        width: 20px;
        transition: all .3s; }
        header.scroll #menuBtn .txt {
          width: 0;
          overflow: hidden;
          color: transparent; }
    header.isMenuOpen {
      height: 100px;
      padding: 30px 5% 0; }
      header.isMenuOpen #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.isMenuOpen #siteName img {
          width: 100%;
          height: auto;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        width: auto; }
        header.isMenuOpen #menuBtn .txt {
          width: auto;
          color: #fff;
          overflow: visible; }
    header #siteName {
      width: 220px;
      height: 70px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 220px 70px;
      background-repeat: no-repeat;
      position: relative; }
      header #siteName img {
        width: 100%;
        height: auto;
        transition: all .3s; }
      header #siteName .memberTag {
        width: 60px;
        height: 14px;
        position: absolute;
        bottom: 10px;
        right: -70px; }
        header #siteName .memberTag img {
          width: 100%;
          height: auto; }
    header.scroll {
      height: 70px;
      padding: 10px 5% 0; }
      header.scroll #siteName {
        width: 158px;
        height: 50px;
        background-size: 158px 50px; }
        header.scroll #siteName img {
          width: 158px;
          height: 50px;
          opacity: 0; }
      header.scroll #menuBtn {
        width: 20px;
        transition: all .3s; }
        header.scroll #menuBtn .txt {
          width: 0;
          overflow: hidden;
          color: transparent; }
    header.isMenuOpen {
      height: 70px;
      padding: 10px 5% 0; }
      header.isMenuOpen #siteName {
        width: 158px;
        height: 50px;
        background-size: 158px 50px; }
        header.isMenuOpen #siteName img {
          width: 100%;
          height: auto;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        width: auto; }
        header.isMenuOpen #menuBtn .txt {
          width: auto;
          color: #fff;
          overflow: visible; }
    header #siteName {
      width: 158px;
      height: 50px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 158px 50px;
      background-repeat: no-repeat; }
      header #siteName img {
        width: 100%;
        height: auto;
        transition: all .3s; }
    header #menuBtn {
      width: 20px; }
      header #menuBtn .txt {
        display: none; }
  /* ------------------- menu --------------------- */
  #menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    z-index: 998;
    transform: scale(0);
    opacity: 0;
    background-image: url(../images/common/check-bg.png);
    padding: 10px; }
    #menu.active {
      transform: scale(0.7);
      opacity: 1;
      animation: menuAnim .3s forwards; }
  @keyframes menuAnim {
    100% {
      transform: scale(1); } }
    #menu #menuBody {
      width: 100%;
      height: 100%;
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center; }
      #menu #menuBody .menuList ul {
        list-style: none; }
        #menu #menuBody .menuList ul li {
          margin-bottom: .4em; }
          #menu #menuBody .menuList ul li a {
            text-decoration: none;
            color: #fff;
            line-height: 1;
            display: inline-block;
            position: relative;
            padding-bottom: .1em; }
            #menu #menuBody .menuList ul li a:after {
              content: "";
              display: block;
              width: 0;
              height: 3px;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: all .4s; }
            #menu #menuBody .menuList ul li a:hover:after {
              width: 100%; }
      #menu #menuBody #mainMenu ul li a {
        font-size: 4vw;
        font-weight: 700; }
      #menu #menuBody #subMenu {
        margin-left: 100px; }
        #menu #menuBody #subMenu ul {
          width: 60px; }
          #menu #menuBody #subMenu ul li {
            width: 42px;
            margin-bottom: 25px;
            transition: all .3s;
            margin-left: 9px; }
            #menu #menuBody #subMenu ul li:hover {
              width: 60px;
              margin-left: 0; }
            #menu #menuBody #subMenu ul li a {
              display: block;
              width: 100%;
              text-decoration: none; }
              #menu #menuBody #subMenu ul li a:after {
                display: none; }
              #menu #menuBody #subMenu ul li a img {
                width: 100%;
                height: auto; }
    #menu #menuBody .menuList ul li {
      margin-bottom: .4em; }
    #menu #menuBody #mainMenu ul li a {
      font-size: 50px;
      font-weight: 700; }
    #menu #menuBody #subMenu {
      margin-left: 100px; }
      #menu #menuBody #subMenu ul li a {
        font-size: 28px; }
    #menu #menuBody {
      display: block; }
      #menu #menuBody #mainMenu ul {
        width: 90%;
        margin: 90px auto 0; }
        #menu #menuBody #mainMenu ul li a {
          font-size: 30px;
          font-weight: 700; }
      #menu #menuBody #subMenu {
        width: 90%;
        margin: 30px auto 0; }
        #menu #menuBody #subMenu ul {
          display: flex;
          width: 100%;
          margin: 0 auto; }
          #menu #menuBody #subMenu ul li {
            margin: 0 15px 0 0; }
            #menu #menuBody #subMenu ul li a {
              display: block;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center; }
  /* ------------------------- footer ----------------------- */
  footer {
    background-color: #050901;
    position: relative;
    color: #fff;
    margin-top: 100px;
    padding-top: 85px;
    margin-top: 80px;
    padding-top: 40px; }
    footer #footerInner {
      max-width: 1280px;
      width: 90%;
      margin: 0 auto 0; }
      footer #footerInner #footerCatch {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        line-height: 1.3; }
      footer #footerInner #kochira {
        font-size: 18px;
        line-height: 1;
        text-align: center;
        margin-top: 25px; }
      footer #footerInner #footerEntry {
        margin-top: 40px; }
        footer #footerInner #footerEntry a {
          width: 485px;
          height: 70px;
          background-color: #fff;
          border: 2px solid #71bd29;
          margin: 0 auto;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #fff;
          transition: all .3s;
          position: relative;
          z-index: 1;
          font-weight: 700;
          transition: all .3s;
          box-shadow: 4px 6px 0 #b4e585;
          font-weight: 700; }
          footer #footerInner #footerEntry a:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #71bd29;
            z-index: -1;
            transition: all .3s; }
          footer #footerInner #footerEntry a:hover {
            color: #71bd29; }
            footer #footerInner #footerEntry a:hover:after {
              width: 0; }
      footer #footerInner #others {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        margin-top: 60px;
        padding: 30px 0; }
        footer #footerInner #others h2 {
          font-size: 15px;
          line-height: 1;
          color: rgba(255, 255, 255, 0.6);
          text-align: center; }
        footer #footerInner #others ul {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px; }
          footer #footerInner #others ul li {
            margin: 0 20px; }
            footer #footerInner #others ul li a {
              opacity: .6;
              transition: all .3s;
              max-width: 140px;
              display: block;
              text-align: center; }
              footer #footerInner #others ul li a svg {
                height: 80px;
                fill: #fff; }
              footer #footerInner #others ul li a:hover {
                opacity: 1; }
            footer #footerInner #others ul li:nth-of-type(3) {
              height: 20px; }
              footer #footerInner #others ul li:nth-of-type(3) a svg {
                height: 20px; }
      footer #footerInner #footerCopy {
        margin-top: 35px; }
        footer #footerInner #footerCopy #footerMenu {
          list-style: none;
          text-align: center; }
          footer #footerInner #footerCopy #footerMenu li {
            display: inline-block;
            font-size: 12px;
            line-height: 1; }
            footer #footerInner #footerCopy #footerMenu li a {
              color: #fff;
              text-decoration: none; }
              footer #footerInner #footerCopy #footerMenu li a:hover {
                text-decoration: underline; }
            footer #footerInner #footerCopy #footerMenu li:not(:last-of-type):after {
              content: "/";
              display: inline-block;
              width: 20px;
              text-align: center;
              color: #fff; }
        footer #footerInner #footerCopy #ceo {
          text-align: center;
          margin-top: 30px;
          vertical-align: middle;
          padding-left: 105px; }
          footer #footerInner #footerCopy #ceo a {
            display: inline-block;
            border: 1px solid #fff;
            color: #fff;
            text-decoration: none;
            font-size: 12px;
            padding: 5px 10px;
            vertical-align: middle;
            transition: all .3s;
            margin-right: 10px; }
            footer #footerInner #footerCopy #ceo a:hover {
              background-color: #71bd29; }
          footer #footerInner #footerCopy #ceo img {
            vertical-align: middle; }
        footer #footerInner #footerCopy .copy {
          text-align: center;
          line-height: 1;
          font-size: 10px;
          padding: 30px 0; }
    footer #footerInner #footerCatch {
      font-size: 30px; }
    footer #footerInner #kochira {
      font-size: 16px;
      margin-top: 15px; }
    footer #footerInner #footerEntry {
      margin-top: 20px; }
      footer #footerInner #footerEntry a {
        max-width: 485px;
        width: 80%;
        height: 50px;
        font-weight: 700; }
    footer #footerInner #others {
      margin-top: 30px;
      padding: 15px 0; }
      footer #footerInner #others h2 {
        font-size: 15px; }
      footer #footerInner #others ul {
        margin-top: 20px; }
        footer #footerInner #others ul li {
          margin: 0 10px; }
          footer #footerInner #others ul li a {
            opacity: .6; }
          footer #footerInner #others ul li:nth-of-type(3) {
            height: 20px; }
            footer #footerInner #others ul li:nth-of-type(3) a svg {
              max-width: 100%; }
    footer #footerInner #footerCopy {
      margin-top: 20px; }
      footer #footerInner #footerCopy #ceo {
        margin-top: 20px; }
    footer #footerInner #footerCatch {
      font-size: 25px; }
    footer #footerInner #kochira {
      font-size: 16px;
      margin-top: 15px; }
    footer #footerInner #footerEntry {
      margin-top: 20px; }
      footer #footerInner #footerEntry a {
        font-size: 14px; }
    footer #footerInner #others li {
      max-width: 32%; }
      footer #footerInner #others li a {
        display: block;
        width: 100%;
        height: auto; }
        footer #footerInner #others li a img {
          width: 100%;
          height: auto; }
  #pagetop {
    width: 28px;
    height: 15px;
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    margin: 0 auto;
    cursor: pointer; }
    #pagetop:hover {
      animation: pageTop 1s infinite; }
  @keyframes pageTop {
    30% {
      top: -40px; } }
  #joinUs {
    position: fixed; }
  .forPC {
    display: none !important; } }

@media screen and (min-width: 651px) and (max-width: 999px) {
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; }
  /*
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
*/
  /*
@function slick-image-url($url) {
	@if function-exists(image-url) {
			@return image-url($url);
	}
	@else {
			@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
			@return font-url($url);
	}
	@else {
			@return url($slick-font-path + $url);
	}
}
*/
  /* Slider

.slick-list {
	.slick-loading & {
			background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}
*/
  /* Icons 
@if $slick-font-family == "slick" {
	@font-face {
			font-family: "slick";
			src: slick-font-url("slick.eot");
			src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
			font-weight: normal;
			font-style: normal;
	}
}
*/
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    .slick-prev:hover, .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .slick-prev:hover:before, .slick-prev:focus:before,
      .slick-next:hover:before,
      .slick-next:focus:before {
        opacity: 1; }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0.25; }
    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .slick-prev {
    left: -25px; }
    [dir="rtl"] .slick-prev {
      left: auto;
      right: -25px; }
  .slick-next {
    right: -25px; }
    [dir="rtl"] .slick-next {
      left: -25px;
      right: auto; }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .slick-dots li button:hover, .slick-dots li button:focus {
          outline: none; }
          .slick-dots li button:hover:before, .slick-dots li button:focus:before {
            opacity: 1; }
        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          font-size: 6px;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
  body {
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
    font-weight: 500; }
  .eng {
    font-family: "Oswald", "sans-serif", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "meiryo"; }
  .sten {
    font-family: "Allerta Stencil", sans-serif;
    font-weight: 400 !important; }
  /* ----------------scroll view ------------------- */
  .content {
    transform: translateX(-80px);
    opacity: 0;
    transition: all 1s; }
    .content.view {
      transform: translateX(0);
      opacity: 1; }
  /* ---------------- header ----------------------- */
  header {
    min-width: 100%;
    height: 160px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    padding: 64px 80px 0;
    transition: all .3s;
    height: 100px;
    padding: 30px 5% 0;
    transition: all .3s;
    overflow: hidden; }
    header.scroll {
      height: 0;
      top: 45px;
      padding: 15px 80px 0; }
      header.scroll #backG {
        bottom: 0; }
      header.scroll #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.scroll #siteName img {
          width: 220px;
          height: 70px;
          opacity: 0; }
      header.scroll #menuBtn .txt {
        color: #888788; }
    header.isMenuOpen {
      height: 1px;
      padding: 0px 80px 0;
      top: 0; }
      header.isMenuOpen #siteName {
        width: 302px;
        height: 96px;
        background-size: 302px 96px;
        margin-top: 222px; }
        header.isMenuOpen #siteName img {
          width: 302px;
          height: 96px;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        margin-top: 222px; }
        header.isMenuOpen #menuBtn .txt {
          color: #fff; }
    header #siteName {
      width: 302px;
      height: 96px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 302px 96px;
      background-repeat: no-repeat;
      position: relative; }
      header #siteName img {
        width: 302px;
        height: 96px;
        transition: all .3s; }
      header #siteName .memberTag {
        width: 111px;
        height: 25px;
        position: absolute;
        bottom: 21px;
        right: -120px; }
        header #siteName .memberTag img {
          width: 100%;
          height: auto; }
    header #menuBtn {
      width: 87px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 22px; }
      header #menuBtn #icon {
        width: 20px;
        height: 21px;
        position: relative; }
        header #menuBtn #icon span {
          display: block;
          width: 20px;
          height: 3px;
          background-color: #71bd29;
          position: absolute;
          transition: all .4s; }
          header #menuBtn #icon span:nth-of-type(1) {
            top: 0;
            left: 0; }
          header #menuBtn #icon span:nth-of-type(2) {
            top: 9px;
            left: 0;
            width: 14px; }
          header #menuBtn #icon span:nth-of-type(3) {
            top: 18px;
            left: 0; }
      header #menuBtn .txt {
        font-size: 22px;
        line-height: 1;
        color: #fff; }
      header #menuBtn:hover #icon span:nth-of-type(2) {
        width: 20px; }
      header #menuBtn.active #icon span:nth-of-type(1) {
        top: 11px;
        left: 0;
        transform: rotate(315deg);
        background-color: #fff; }
      header #menuBtn.active #icon span:nth-of-type(2) {
        opacity: 0; }
      header #menuBtn.active #icon span:nth-of-type(3) {
        top: 11px;
        left: 0;
        transform: rotate(-315deg);
        background-color: #fff; }
    header.scroll {
      height: 90px;
      padding: 10px 5% 0; }
      header.scroll #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.scroll #siteName img {
          width: 220px;
          height: 70px;
          opacity: 0; }
      header.scroll #menuBtn {
        width: 20px;
        transition: all .3s; }
        header.scroll #menuBtn .txt {
          width: 0;
          overflow: hidden;
          color: transparent; }
    header.isMenuOpen {
      height: 100px;
      padding: 30px 5% 0; }
      header.isMenuOpen #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.isMenuOpen #siteName img {
          width: 100%;
          height: auto;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        width: auto; }
        header.isMenuOpen #menuBtn .txt {
          width: auto;
          color: #fff;
          overflow: visible; }
    header #siteName {
      width: 220px;
      height: 70px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 220px 70px;
      background-repeat: no-repeat;
      position: relative; }
      header #siteName img {
        width: 100%;
        height: auto;
        transition: all .3s; }
      header #siteName .memberTag {
        width: 60px;
        height: 14px;
        position: absolute;
        bottom: 10px;
        right: -70px; }
        header #siteName .memberTag img {
          width: 100%;
          height: auto; }
  /* ------------------- menu --------------------- */
  #menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    z-index: 998;
    transform: scale(0);
    opacity: 0;
    background-image: url(../images/common/check-bg.png);
    padding: 10px; }
    #menu.active {
      transform: scale(0.7);
      opacity: 1;
      animation: menuAnim .3s forwards; }
  @keyframes menuAnim {
    100% {
      transform: scale(1); } }
    #menu #menuBody {
      width: 100%;
      height: 100%;
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center; }
      #menu #menuBody .menuList ul {
        list-style: none; }
        #menu #menuBody .menuList ul li {
          margin-bottom: .4em; }
          #menu #menuBody .menuList ul li a {
            text-decoration: none;
            color: #fff;
            line-height: 1;
            display: inline-block;
            position: relative;
            padding-bottom: .1em; }
            #menu #menuBody .menuList ul li a:after {
              content: "";
              display: block;
              width: 0;
              height: 3px;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: all .4s; }
            #menu #menuBody .menuList ul li a:hover:after {
              width: 100%; }
      #menu #menuBody #mainMenu ul li a {
        font-size: 4vw;
        font-weight: 700; }
      #menu #menuBody #subMenu {
        margin-left: 100px; }
        #menu #menuBody #subMenu ul {
          width: 60px; }
          #menu #menuBody #subMenu ul li {
            width: 42px;
            margin-bottom: 25px;
            transition: all .3s;
            margin-left: 9px; }
            #menu #menuBody #subMenu ul li:hover {
              width: 60px;
              margin-left: 0; }
            #menu #menuBody #subMenu ul li a {
              display: block;
              width: 100%;
              text-decoration: none; }
              #menu #menuBody #subMenu ul li a:after {
                display: none; }
              #menu #menuBody #subMenu ul li a img {
                width: 100%;
                height: auto; }
    #menu #menuBody .menuList ul li {
      margin-bottom: .4em; }
    #menu #menuBody #mainMenu ul li a {
      font-size: 50px;
      font-weight: 700; }
    #menu #menuBody #subMenu {
      margin-left: 100px; }
      #menu #menuBody #subMenu ul li a {
        font-size: 28px; }
  /* ------------------------- footer ----------------------- */
  footer {
    background-color: #050901;
    position: relative;
    color: #fff;
    margin-top: 100px;
    padding-top: 85px;
    margin-top: 80px;
    padding-top: 40px; }
    footer #footerInner {
      max-width: 1280px;
      width: 90%;
      margin: 0 auto 0; }
      footer #footerInner #footerCatch {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        line-height: 1.3; }
      footer #footerInner #kochira {
        font-size: 18px;
        line-height: 1;
        text-align: center;
        margin-top: 25px; }
      footer #footerInner #footerEntry {
        margin-top: 40px; }
        footer #footerInner #footerEntry a {
          width: 485px;
          height: 70px;
          background-color: #fff;
          border: 2px solid #71bd29;
          margin: 0 auto;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #fff;
          transition: all .3s;
          position: relative;
          z-index: 1;
          font-weight: 700;
          transition: all .3s;
          box-shadow: 4px 6px 0 #b4e585;
          font-weight: 700; }
          footer #footerInner #footerEntry a:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #71bd29;
            z-index: -1;
            transition: all .3s; }
          footer #footerInner #footerEntry a:hover {
            color: #71bd29; }
            footer #footerInner #footerEntry a:hover:after {
              width: 0; }
      footer #footerInner #others {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        margin-top: 60px;
        padding: 30px 0; }
        footer #footerInner #others h2 {
          font-size: 15px;
          line-height: 1;
          color: rgba(255, 255, 255, 0.6);
          text-align: center; }
        footer #footerInner #others ul {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px; }
          footer #footerInner #others ul li {
            margin: 0 20px; }
            footer #footerInner #others ul li a {
              opacity: .6;
              transition: all .3s;
              max-width: 140px;
              display: block;
              text-align: center; }
              footer #footerInner #others ul li a svg {
                height: 80px;
                fill: #fff; }
              footer #footerInner #others ul li a:hover {
                opacity: 1; }
            footer #footerInner #others ul li:nth-of-type(3) {
              height: 20px; }
              footer #footerInner #others ul li:nth-of-type(3) a svg {
                height: 20px; }
      footer #footerInner #footerCopy {
        margin-top: 35px; }
        footer #footerInner #footerCopy #footerMenu {
          list-style: none;
          text-align: center; }
          footer #footerInner #footerCopy #footerMenu li {
            display: inline-block;
            font-size: 12px;
            line-height: 1; }
            footer #footerInner #footerCopy #footerMenu li a {
              color: #fff;
              text-decoration: none; }
              footer #footerInner #footerCopy #footerMenu li a:hover {
                text-decoration: underline; }
            footer #footerInner #footerCopy #footerMenu li:not(:last-of-type):after {
              content: "/";
              display: inline-block;
              width: 20px;
              text-align: center;
              color: #fff; }
        footer #footerInner #footerCopy #ceo {
          text-align: center;
          margin-top: 30px;
          vertical-align: middle;
          padding-left: 105px; }
          footer #footerInner #footerCopy #ceo a {
            display: inline-block;
            border: 1px solid #fff;
            color: #fff;
            text-decoration: none;
            font-size: 12px;
            padding: 5px 10px;
            vertical-align: middle;
            transition: all .3s;
            margin-right: 10px; }
            footer #footerInner #footerCopy #ceo a:hover {
              background-color: #71bd29; }
          footer #footerInner #footerCopy #ceo img {
            vertical-align: middle; }
        footer #footerInner #footerCopy .copy {
          text-align: center;
          line-height: 1;
          font-size: 10px;
          padding: 30px 0; }
    footer #footerInner #footerCatch {
      font-size: 30px; }
    footer #footerInner #kochira {
      font-size: 16px;
      margin-top: 15px; }
    footer #footerInner #footerEntry {
      margin-top: 20px; }
      footer #footerInner #footerEntry a {
        max-width: 485px;
        width: 80%;
        height: 50px;
        font-weight: 700; }
    footer #footerInner #others {
      margin-top: 30px;
      padding: 15px 0; }
      footer #footerInner #others h2 {
        font-size: 15px; }
      footer #footerInner #others ul {
        margin-top: 20px; }
        footer #footerInner #others ul li {
          margin: 0 10px; }
          footer #footerInner #others ul li a {
            opacity: .6; }
          footer #footerInner #others ul li:nth-of-type(3) {
            height: 20px; }
            footer #footerInner #others ul li:nth-of-type(3) a svg {
              max-width: 100%; }
    footer #footerInner #footerCopy {
      margin-top: 20px; }
      footer #footerInner #footerCopy #ceo {
        margin-top: 20px; }
  #pagetop {
    width: 28px;
    height: 15px;
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    margin: 0 auto;
    cursor: pointer; }
    #pagetop:hover {
      animation: pageTop 1s infinite; }
  @keyframes pageTop {
    30% {
      top: -40px; } }
  #joinUs {
    position: fixed; } }

@media print, screen and (min-width: 1000px) {
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; }
  /*
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
*/
  /*
@function slick-image-url($url) {
	@if function-exists(image-url) {
			@return image-url($url);
	}
	@else {
			@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
			@return font-url($url);
	}
	@else {
			@return url($slick-font-path + $url);
	}
}
*/
  /* Slider

.slick-list {
	.slick-loading & {
			background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}
*/
  /* Icons 
@if $slick-font-family == "slick" {
	@font-face {
			font-family: "slick";
			src: slick-font-url("slick.eot");
			src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
			font-weight: normal;
			font-style: normal;
	}
}
*/
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    .slick-prev:hover, .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .slick-prev:hover:before, .slick-prev:focus:before,
      .slick-next:hover:before,
      .slick-next:focus:before {
        opacity: 1; }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0.25; }
    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .slick-prev {
    left: -25px; }
    [dir="rtl"] .slick-prev {
      left: auto;
      right: -25px; }
  .slick-next {
    right: -25px; }
    [dir="rtl"] .slick-next {
      left: -25px;
      right: auto; }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .slick-dots li button:hover, .slick-dots li button:focus {
          outline: none; }
          .slick-dots li button:hover:before, .slick-dots li button:focus:before {
            opacity: 1; }
        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          font-size: 6px;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
  body {
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
    font-weight: 500; }
  .eng {
    font-family: "Oswald", "sans-serif", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "meiryo"; }
  .sten {
    font-family: "Allerta Stencil", sans-serif;
    font-weight: 400 !important; }
  /* ----------------scroll view ------------------- */
  .content {
    transform: translateX(-80px);
    opacity: 0;
    transition: all 1s; }
    .content.view {
      transform: translateX(0);
      opacity: 1; }
  /* ---------------- header ----------------------- */
  header {
    min-width: 100%;
    height: 160px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    padding: 64px 80px 0;
    transition: all .3s; }
    header.scroll {
      height: 0;
      top: 45px;
      padding: 15px 80px 0; }
      header.scroll #backG {
        bottom: 0; }
      header.scroll #siteName {
        width: 220px;
        height: 70px;
        background-size: 220px 70px; }
        header.scroll #siteName img {
          width: 220px;
          height: 70px;
          opacity: 0; }
      header.scroll #menuBtn .txt {
        color: #888788; }
    header.isMenuOpen {
      height: 1px;
      padding: 0px 80px 0;
      top: 0; }
      header.isMenuOpen #siteName {
        width: 302px;
        height: 96px;
        background-size: 302px 96px;
        margin-top: 222px; }
        header.isMenuOpen #siteName img {
          width: 302px;
          height: 96px;
          opacity: 1; }
      header.isMenuOpen #menuBtn {
        margin-top: 222px; }
        header.isMenuOpen #menuBtn .txt {
          color: #fff; }
    header #siteName {
      width: 302px;
      height: 96px;
      transition: all .3s;
      background-image: url(../images/common/logo2.png);
      background-size: 302px 96px;
      background-repeat: no-repeat;
      position: relative; }
      header #siteName img {
        width: 302px;
        height: 96px;
        transition: all .3s; }
      header #siteName .memberTag {
        width: 111px;
        height: 25px;
        position: absolute;
        bottom: 21px;
        right: -120px; }
        header #siteName .memberTag img {
          width: 100%;
          height: auto; }
    header #menuBtn {
      width: 87px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 22px; }
      header #menuBtn #icon {
        width: 20px;
        height: 21px;
        position: relative; }
        header #menuBtn #icon span {
          display: block;
          width: 20px;
          height: 3px;
          background-color: #71bd29;
          position: absolute;
          transition: all .4s; }
          header #menuBtn #icon span:nth-of-type(1) {
            top: 0;
            left: 0; }
          header #menuBtn #icon span:nth-of-type(2) {
            top: 9px;
            left: 0;
            width: 14px; }
          header #menuBtn #icon span:nth-of-type(3) {
            top: 18px;
            left: 0; }
      header #menuBtn .txt {
        font-size: 22px;
        line-height: 1;
        color: #fff; }
      header #menuBtn:hover #icon span:nth-of-type(2) {
        width: 20px; }
      header #menuBtn.active #icon span:nth-of-type(1) {
        top: 11px;
        left: 0;
        transform: rotate(315deg);
        background-color: #fff; }
      header #menuBtn.active #icon span:nth-of-type(2) {
        opacity: 0; }
      header #menuBtn.active #icon span:nth-of-type(3) {
        top: 11px;
        left: 0;
        transform: rotate(-315deg);
        background-color: #fff; }
  /* ------------------- menu --------------------- */
  #menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    z-index: 998;
    transform: scale(0);
    opacity: 0;
    background-image: url(../images/common/check-bg.png); }
    #menu.active {
      transform: scale(0.7);
      opacity: 1;
      animation: menuAnim .3s forwards; }
  @keyframes menuAnim {
    100% {
      transform: scale(1); } }
    #menu #menuBody {
      width: 100%;
      height: 100%;
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center; }
      #menu #menuBody .menuList ul {
        list-style: none; }
        #menu #menuBody .menuList ul li {
          margin-bottom: .4em; }
          #menu #menuBody .menuList ul li a {
            text-decoration: none;
            color: #fff;
            line-height: 1;
            display: inline-block;
            position: relative;
            padding-bottom: .1em; }
            #menu #menuBody .menuList ul li a:after {
              content: "";
              display: block;
              width: 0;
              height: 3px;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: all .4s; }
            #menu #menuBody .menuList ul li a:hover:after {
              width: 100%; }
      #menu #menuBody #mainMenu ul li a {
        font-size: 4vw;
        font-weight: 700; }
      #menu #menuBody #subMenu {
        margin-left: 100px; }
        #menu #menuBody #subMenu ul {
          width: 60px; }
          #menu #menuBody #subMenu ul li {
            width: 42px;
            margin-bottom: 25px;
            transition: all .3s;
            margin-left: 9px; }
            #menu #menuBody #subMenu ul li:hover {
              width: 60px;
              margin-left: 0; }
            #menu #menuBody #subMenu ul li a {
              display: block;
              width: 100%;
              text-decoration: none; }
              #menu #menuBody #subMenu ul li a:after {
                display: none; }
              #menu #menuBody #subMenu ul li a img {
                width: 100%;
                height: auto; }
  /* ------------------------- footer ----------------------- */
  footer {
    background-color: #050901;
    position: relative;
    color: #fff;
    margin-top: 100px;
    padding-top: 85px; }
    footer #footerInner {
      max-width: 1280px;
      width: 90%;
      margin: 0 auto 0; }
      footer #footerInner #footerCatch {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        line-height: 1.3; }
      footer #footerInner #kochira {
        font-size: 18px;
        line-height: 1;
        text-align: center;
        margin-top: 25px; }
      footer #footerInner #footerEntry {
        margin-top: 40px; }
        footer #footerInner #footerEntry a {
          width: 485px;
          height: 70px;
          background-color: #fff;
          border: 2px solid #71bd29;
          margin: 0 auto;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #fff;
          transition: all .3s;
          position: relative;
          z-index: 1;
          font-weight: 700;
          transition: all .3s;
          box-shadow: 4px 6px 0 #b4e585;
          font-weight: 700; }
          footer #footerInner #footerEntry a:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #71bd29;
            z-index: -1;
            transition: all .3s; }
          footer #footerInner #footerEntry a:hover {
            color: #71bd29; }
            footer #footerInner #footerEntry a:hover:after {
              width: 0; }
      footer #footerInner #others {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        margin-top: 60px;
        padding: 30px 0; }
        footer #footerInner #others h2 {
          font-size: 15px;
          line-height: 1;
          color: rgba(255, 255, 255, 0.6);
          text-align: center; }
        footer #footerInner #others ul {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px; }
          footer #footerInner #others ul li {
            margin: 0 20px; }
            footer #footerInner #others ul li a {
              opacity: .6;
              transition: all .3s;
              max-width: 140px;
              display: block;
              text-align: center; }
              footer #footerInner #others ul li a svg {
                height: 80px;
                fill: #fff; }
              footer #footerInner #others ul li a:hover {
                opacity: 1; }
            footer #footerInner #others ul li:nth-of-type(3) {
              height: 20px; }
              footer #footerInner #others ul li:nth-of-type(3) a svg {
                height: 20px; }
      footer #footerInner #footerCopy {
        margin-top: 35px; }
        footer #footerInner #footerCopy #footerMenu {
          list-style: none;
          text-align: center; }
          footer #footerInner #footerCopy #footerMenu li {
            display: inline-block;
            font-size: 12px;
            line-height: 1; }
            footer #footerInner #footerCopy #footerMenu li a {
              color: #fff;
              text-decoration: none; }
              footer #footerInner #footerCopy #footerMenu li a:hover {
                text-decoration: underline; }
            footer #footerInner #footerCopy #footerMenu li:not(:last-of-type):after {
              content: "/";
              display: inline-block;
              width: 20px;
              text-align: center;
              color: #fff; }
        footer #footerInner #footerCopy #ceo {
          text-align: center;
          margin-top: 30px;
          vertical-align: middle;
          padding-left: 105px; }
          footer #footerInner #footerCopy #ceo a {
            display: inline-block;
            border: 1px solid #fff;
            color: #fff;
            text-decoration: none;
            font-size: 12px;
            padding: 5px 10px;
            vertical-align: middle;
            transition: all .3s;
            margin-right: 10px; }
            footer #footerInner #footerCopy #ceo a:hover {
              background-color: #71bd29; }
          footer #footerInner #footerCopy #ceo img {
            vertical-align: middle; }
        footer #footerInner #footerCopy .copy {
          text-align: center;
          line-height: 1;
          font-size: 10px;
          padding: 30px 0; }
  #pagetop {
    width: 28px;
    height: 15px;
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    margin: 0 auto;
    cursor: pointer; }
    #pagetop:hover {
      animation: pageTop 1s infinite; }
  @keyframes pageTop {
    30% {
      top: -40px; } }
  #joinUs {
    position: fixed;
    width: 167px;
    height: 167px;
    bottom: 50px;
    right: 50px;
    background-image: url(../images/common/joinus.png);
    opacity: 0;
    transform: scale(0);
    transition: all .2s;
    z-index: 9999; }
    #joinUs.scroll {
      opacity: 1;
      transform: scale(1); }
    #joinUs a {
      display: block;
      width: 100%;
      height: 100%; }
      #joinUs a img {
        transform: scale(0);
        transition: all .2s ease-in; }
      #joinUs a:hover img {
        transform: scale(1); } }
