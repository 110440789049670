@charset "UTF-8";

// IMPPRT INIT
@import "lib/_sanitize";    // Reset CSS
@import "_setting";

/* font */
@import url('https://fonts.googleapis.com/css?family=Oswald:400,700');
@import url('https://fonts.googleapis.com/css?family=Allerta+Stencil');


// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and(max-width:$breakpoint_sp) {
	$type: sp;
	@import "_layout";
	.forPC{
		display: none !important;
	}
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media  screen and (min-width:$breakpoint_sp + 1) and(max-width:$breakpoint_pc - 1) {
	$type: tb;
	@import "_layout";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_layout";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
